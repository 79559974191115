import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "welcome": "Welcome to Netony",
      "welcome-description": "Designing and building system to increase business success",
      "talk-button":"SHALL WE TALK?",
      "business-title":"Take your business to the next level",
      "netony-title":"What is Netony?",
      "netony-description":"",
      "helping":"Helping Businesses Worldwide",
      "offer":"What We Offer",
      "modernization":"Modernizing and Integration",
      "modernization-description":"Modernizing and integrating existing businesss with modern technology",
      "goal":"Goal Assistance",
      "goal-description":"Integrate technology into business to achieve goals",
      "idea":"Idea Fulfillment",
      "idea-description":"Turning buiness Ideas into functional business",
      "enhance":"Business Enhancement",
      "enhance-description":"Increasing reach of buiness to increase revenue using technology",
      "ready":"Ready to level up your business?",
      "consultation":"Every successful partnership begins with a conversation.",
      "schedule":"SCHEDULE A CONSULTATION"
    }
  },
  jp: {
    translation: {
      "welcome": "ネトニーへようこそ",
      "welcome-description":"ビジネスの成功を高めるためのシステムの設計と構築",
      "talk-button":"話をしましょう？",
      "business-title":"ビジネスを次のレベルに引き上げる",
      "netony-title":"ネトニーとは？",
      "netony-description":"",
      "helping":"世界中のビジネスを支援",
      "offer":"私たちが提供するもの",
      "modernization":"近代化と統合",
      "modernization-description":"既存のビジネスを最新化して最新のテクノロジーと統合する",
      "goal":"ゴールアシスタンス",
      "goal-description":"テクノロジーをビジネスに統合して目標を達成する",
      "idea":"アイデアの実現",
      "idea-description":"ビジネスアイデアを機能的なビジネスに変える",
      "enhance":"ビジネスの強化",
      "enhance-description":"テクノロジーを使用して収益を増やすためにビジネスのリーチを拡大する",
      "ready":"あなたのビジネスをレベルアップする準備はできましたか？",
      "consultation":"成功するパートナーシップはすべて、会話から始まります。",
      "schedule":"相談のスケジュール"

    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;