import React, { Component } from 'react';
import './About.css';
import { NavLink } from "react-router-dom";
export class About extends Component {
    
    render() {
        return (
            <div className="About">
                <div className="about grid-container about-container">
                    
                    <div className="about-grid-item about-image"></div>
                    <div className="about-grid-item">
                        <div className="">
                            <p className="description">Who We Are </p>
                            <p className="paragraph">We bring modern solution to client using technology. Our solutions are tailored for each of our client problems to improve and enhance their business.</p>

                            <NavLink to="/Contacts"><button className="gradient-button talk-button">SHALL WE TALK?</button></NavLink>
                        </div>
                    </div>
                </div>

                <div className="about grid-container about-container">
                    <div className="about-grid-item">
                        <div className="">
                            <p className="description">Mission</p>
                            <p className="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Donec ac augue mollis, pulvinar nunc eu, elementum diam.</p>
                        </div>
                    </div>
                    <div className="about-grid-item">
                        <div className="">
                            <p className="description">Client-Oriented</p>
                            <p className="paragraph">We take great effort ensure client project succeed, we accomplish this my addressing al clientals needs to ensure client's project work as desired.</p>
                        </div>
                    </div>
                </div>

                <div className="founder">
                    <div className="about grid-container about-container">
                        <div className="about-grid-item founder-item">
                            <div>
                                <p className="description">About The Founder</p>
                                <p className="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. Ut eleifend pretium dui ut facilisis. Morbi fermentum nisi lacus, id condimentum dolor porta non. Sed gravida egestas interdum. In hac habitasse platea dictumst. Phasellus tincidunt sollicitudin ex, a commodo lorem faucibus ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam.  </p>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>

                <div className="project">
                    <div className="about about-container project-grid-container">
                        <div className="about-grid-item">
                            <div>
                                <p className="description white-text">Have a project for us?</p>
                                <p className="paragraph white-text">Need someone to manage and build your idea? We have the expertise and resource to get your project off the ground and achieving your desired results. </p>
                            </div>
                        </div>
                        <div className="about-grid-item">
                            <div>
                                <NavLink to="/Contacts"><button className="white-button talk-button">CONTACT US TODAY!</button></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default About