import { IoAddOutline,IoRemoveOutline } from "react-icons/io5";
import { useState } from 'react';

export default function FaqDropdown() {

    const [dropdownOpen, setDropdownOpen ] = useState(false);
    const [dropdownOpen2, setDropdownOpen2 ] = useState(false);
    const [dropdownOpen3, setDropdownOpen3 ] = useState(false);
    const [dropdownOpen4, setDropdownOpen4 ] = useState(false);
    const [dropdownOpen5, setDropdownOpen5 ] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const toggleDropdown2 = () => {
        setDropdownOpen2(!dropdownOpen2);
    }

    const toggleDropdown3 = () => {
        setDropdownOpen3(!dropdownOpen3);
    }

    const toggleDropdown4 = () => {
        setDropdownOpen4(!dropdownOpen4);
    }

    const toggleDropdown5 = () => {
        setDropdownOpen5(!dropdownOpen5);
    }

    return (
            <>
                <div className="qa-container">
                    <div className="qa-section">
                        <div className="question-section">
                            <p className="qa-description description">Lorem ipsum dolor sit amet.</p>
                            <button className="qa-button" onClick={toggleDropdown}><IoAddOutline className="view qa-icon"/> <IoRemoveOutline className="hide qa-icon"/> </button>
                        </div>
                        <p className="qa-paragraph paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam.</p>    
                    </div>

                    <div className="qa-section">
                        <div className="question-section">
                            <p className="qa-description description">Lorem ipsum dolor sit amet.</p>
                            <button className="qa-button" onClick={toggleDropdown2}><IoAddOutline className="view2 qa-icon"/> <IoRemoveOutline className="hide2 qa-icon"/> </button>
                        </div>
                        <p className="qa-paragraph2 paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam.</p>    
                    </div>

                    <div className="qa-section">
                        <div className="question-section">
                            <p className="qa-description description">Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</p>
                            <button className="qa-button" onClick={toggleDropdown3}><IoAddOutline className="view3 qa-icon"/> <IoRemoveOutline className="hide3 qa-icon"/> </button>
                        </div>
                        <p className="qa-paragraph3 paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam.</p>    
                    </div>

                    <div className="qa-section">
                        <div className="question-section">
                            <p className="qa-description description">Lorem ipsum dolor sit amet.</p>
                            <button className="qa-button" onClick={toggleDropdown4}><IoAddOutline className="view4 qa-icon"/> <IoRemoveOutline className="hide4 qa-icon"/> </button>
                        </div>
                        <p className="qa-paragraph4 paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam.</p>    
                    </div>

                    <div className="qa-section">
                        <div className="question-section">
                            <p className="qa-description description">Lorem ipsum dolor sit amet.</p>
                            <button className="qa-button" onClick={toggleDropdown5}><IoAddOutline className="view5 qa-icon"/> <IoRemoveOutline className="hide5 qa-icon"/> </button>
                        </div>
                        <p className="qa-paragraph5 paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam.</p>    
                    </div>

                    
                </div>

                <style jsx="true">{`
                    .qa-paragraph{
                        display: ${ dropdownOpen ? 'block' : 'none'};
                    }

                    .view{
                        display: ${ dropdownOpen ? 'none' : 'block'};
                    }

                    .hide{
                        display: ${ dropdownOpen ? 'block' : 'none'};
                    }

                    .qa-paragraph2{
                        display: ${ dropdownOpen2 ? 'block' : 'none'};
                    }

                    .view2{
                        display: ${ dropdownOpen2 ? 'none' : 'block'};
                    }

                    .hide2{
                        display: ${ dropdownOpen2 ? 'block' : 'none'};
                    }

                    .qa-paragraph3{
                        display: ${ dropdownOpen3 ? 'block' : 'none'};
                    }

                    .view3{
                        display: ${ dropdownOpen3 ? 'none' : 'block'};
                    }

                    .hide3{
                        display: ${ dropdownOpen3 ? 'block' : 'none'};
                    }

                    .qa-paragraph4{
                        display: ${ dropdownOpen4 ? 'block' : 'none'};
                    }

                    .view4{
                        display: ${ dropdownOpen4 ? 'none' : 'block'};
                    }

                    .hide4{
                        display: ${ dropdownOpen4 ? 'block' : 'none'};
                    }

                    .qa-paragraph5{
                        display: ${ dropdownOpen5 ? 'block' : 'none'};
                    }

                    .view5{
                        display: ${ dropdownOpen5 ? 'none' : 'block'};
                    }

                    .hide5{
                        display: ${ dropdownOpen5 ? 'block' : 'none'};
                    }
            `}</style>
            </>
    );
    
}