import React, { Component } from 'react';
import i18n from '../../i18n';
import { NavLink } from "react-router-dom";

export default function Hamburger({ isOpen }){

    const changeLanguage = () => {
        if (i18n.language == 'en')
            i18n.changeLanguage('jp');
        else
            i18n.changeLanguage('en');
    }

    return (
        <>
            <div className="hamburger-dropdown">
                <h6 className="hamburger-item"><NavLink to="/">HOME</NavLink></h6>
                <h6 className="hamburger-item"><NavLink to="/About">ABOUT</NavLink></h6>
                <h6 className="hamburger-item"><NavLink to="/Services">SERVICES</NavLink></h6>
                <h6 className="hamburger-item"><NavLink to="/Faqs">FAQ</NavLink></h6>
                <h6 className="hamburger-item"><NavLink to="/Contacts">CONTACT US</NavLink></h6>
                <h6 className="hamburger-item" onClick={() => changeLanguage()}>LANGUAGE</h6>
            </div>    
            
            <style jsx="true">{`
                .hamburger-dropdown{
                    display: ${ isOpen ? 'block' : 'none'};
                    padding: 0 10px;
                }

                .hamburger{
                    height: 2rem;
                    display:flex;
                    justify-content: space-around;
                    flex-flow: column nowrap;
                    z-index: 10;
                }
                
                .hamburger-item{
                    height:0.23rem;
                    border-radius: 10px;
                    transform-origin: 1px;
                    transition: all 0.3s linear;
                    color:#3C3B7B;
                    font-weight: normal;
                    font-size:12px;
                }
            `}</style>
        </>
    );
}