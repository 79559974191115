import './Header.css';
import '../Hamburger/Hamburger';
import menuLogo from '../../Assets/images/menu-logo.svg';

import { NavLink } from "react-router-dom";

import { IoMenuOutline, IoGlobeOutline } from "react-icons/io5";
import Hamburger from '../Hamburger/Hamburger';
import { useState } from 'react';
import i18n from '../../i18n';

export default function Header() {
    const changeLanguage = () => {
        if (i18n.language == 'en')
            i18n.changeLanguage('jp');
        else
            i18n.changeLanguage('en');
    }

    const [hamburgerOpen, setHamburgerOpen ] = useState(false);
        
    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    return (
        <header className="App-header">
            <div className="header-grid-container">
                <div className="hamburger-container">
                    <button className="hamburger-button" onClick={toggleHamburger}><IoMenuOutline className="hamburger-logo"/></button>
                    <Hamburger isOpen={hamburgerOpen}/>
                </div>
                <div className="logo-grid-item">
                    <NavLink to="/"><img src={menuLogo} className="menu-logo"></img></NavLink>
                </div>
                <div className="nav-grid-container">
                    <h6 className="nav-item"><NavLink to="/">HOME</NavLink></h6>
                    <h6 className="nav-item"><NavLink to="/About">ABOUT</NavLink></h6>
                    <h6 className="nav-item"><NavLink to="/Services">SERVICES</NavLink></h6>
                    <h6 className="nav-item"><NavLink to="/Faqs">FAQ</NavLink></h6>
                    <NavLink to="/Contacts"><button className="nav-button gradient-button">CONTACT US</button></NavLink>
                    <button className="language-button"><IoGlobeOutline className="language-globe" onClick={() => changeLanguage()}/></button>
                </div>
            </div>
        </header>
    );
    
}