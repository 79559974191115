import React, { Component } from 'react';

import './Contacts.css';
import { IoCallOutline, IoLocationOutline, IoMailOutline } from "react-icons/io5";

export class Contacts extends Component {
    
    render() {
        return (
            <div>
                <div className="contacts contacts-heading">
                    <p className="description">Contacts</p>
                </div>

                <div className="contacts contacts-heading">
                    <div className="contacts-grid-container">
                        <div className="contacts-grid-item">
                            <IoCallOutline className="contact-logo"/>
                            <p className="contact-description">1-001-234-5678</p>
                            <p className="paragraph contact-paragraph">Mon-Fri 8am-10pm</p>
                        </div>
                        <div className="contacts-grid-item">
                            <IoLocationOutline className="contact-logo"/>
                            <p className="contact-description">168-1276, Marunochi, Tokyo, Japan</p>
                            <p className="paragraph contact-paragraph">Office Location</p>
                        </div>
                        <div className="contacts-grid-item">
                            <IoMailOutline className="contact-logo"/>
                            <p className="contact-description">hello@netony.com</p>
                            <p className="paragraph contact-paragraph">Mon-Fri 8am-10pm</p>
                        </div>
                    </div>
                </div>

                <div className="contacts blue">
                    <div className="contacts-container">
                    <p className="description">Drop us a line!</p>
                    <div className="contacts-form">
                        
                        <p className="paragraph contacts-heading-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. </p>
                        <input className="faq-form-input" type="text" placeholder="Name*"></input>
                        <input className="faq-form-input" type="email" placeholder="Email*"></input>
                        <textarea className="faq-form-input" type="text" placeholder="Message*" rows="7"></textarea>

                        <button className="gradient-button send-button">SEND QUESTION</button>
                    </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}
export default Contacts