import React, { Component } from 'react';
import './Home.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

import cone from '../../Assets/images/cone.png';
import cube from '../../Assets/images/cube.png';
import sphere from '../../Assets/images/sphere.png';
import cylinder from '../../Assets/images/cylinder.png';
import bulb from '../../Assets/images/bulb.png';
import box from '../../Assets/images/box.png';
import globe from '../../Assets/images/globe.png';
import target from '../../Assets/images/target.png';

export default function Header() {
    const { t, i18n } = useTranslation();

    return (
        <div className="Home">
            <div className="welcome-banner">
                <div className="welcome-grid-container welcome-container">
                    <div className="welcome-grid-item office-img"></div>
                    <div className="welcome-grid-item">
                        <div className="welcome-content">
                            <p className="title">{t('welcome')}</p>
                            <p className="description welcome-description">{t('welcome-description')}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="welcome grid-container welcome-container">
                <div className="grid-item">
                    <div className="talk-content">
                        <p className="title">Lorem ipsum dolor</p>
                        <p className="description">Lorem ipsum dolor</p>
                        <p className="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. Ut eleifend pretium dui ut facilisis. Morbi fermentum nisi lacus, id condimentum dolor porta non. Sed gravida egestas interdum. In hac habitasse platea dictumst. Phasellus tincidunt sollicitudin ex, a commodo lorem faucibus ac.</p>

                        <NavLink to="/Contacts"><button className="gradient-button talk-button">{t('talk-button')}</button></NavLink>
                    </div>
                </div>
                <div className="shape-grid-item">
                    <div className="talk-content">
                        <div className="talk-grid-container">
                            <div className="content-grid-item">
                                <img className="talk-logo" src={cone}/>
                                <p className="paragraph">Lorem ipsum dolor, sit arnet, consectetur.</p>
                            </div>
                            <div className="content-grid-item">
                                <img className="talk-logo" src={sphere}/>
                                <p className="paragraph">Lorem ipsum dolor, sit arnet, consectetur.</p>
                            </div>
                            <div className="content-grid-item">
                                <img className="talk-logo" src={cylinder}/>
                                <p className="paragraph">Lorem ipsum dolor, sit arnet, consectetur.</p>
                            </div>
                            <div className="content-grid-item">
                                <img className="talk-logo" src={cube}/>
                                <p className="paragraph">Lorem ipsum dolor, sit arnet, consectetur.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid-container lightblue-background">
                <div className="grid-item business-banner"></div>
                <div className="welcome-content">
                    <p className="title">{t('business-title')}</p>
                    <p className="description">{t('netony-title')}</p>
                    <p className="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. Ut eleifend pretium dui ut facilisis. Morbi fermentum nisi lacus, id condimentum dolor porta non. Sed gravida egestas interdum. In hac habitasse platea dictumst. Phasellus tincidunt sollicitudin ex, a commodo lorem faucibus ac.</p>
                </div>
            </div>

            <div className="banner">
                <p className="banner-text">Lorem ipsum dolor sit amet, consectetur adipis cing elit. Donec ac augue mollis.</p>
            </div>

            <div className="offer welcome-container">
                <p className="title">{t('helping')}</p>
                <p className="description">{t('offer')}</p>

                <div className="grid-container">
                    <div className="content-grid-item">
                        <div className="offer-grid-container">
                            <div className="offer-grid-item">
                                <img className="offer-logo" src={globe}/>
                            </div>
                            <div className="offer-grid-item">
                            <p className="offer-description">{t('modernization')}</p>
                                <p className="paragraph offer-paragraph">{t('modernization-description')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="content-grid-item">
                        <div className="offer-grid-container">
                            <div className="offer-grid-item">
                                <img className="offer-logo" src={target}/>
                            </div>
                            <div className="offer-grid-item">
                            <p className="offer-description">{t('goal')}</p>
                                <p className="paragraph offer-paragraph">{t('goal-description')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="content-grid-item">
                        <div className="offer-grid-container">
                            <div className="offer-grid-item">
                                <img className="offer-logo" src={bulb}/>
                            </div>
                            <div className="offer-grid-item">
                            <p className="offer-description">{t('idea')}</p>
                                <p className="paragraph offer-paragraph">{t('idea-description')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="content-grid-item">
                        <div className="offer-grid-container">
                            <div className="offer-grid-item">
                                <img className="offer-logo" src={box}/>
                            </div>
                            <div className="offer-grid-item">
                            <p className="offer-description">{t('enhance')}</p>
                                <p className="paragraph offer-paragraph">{t('enhance-description')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="schedule-banner">
                <div className="grid-container welcome-container">
                    <div className="schedule-banner-content">
                        <p className="title white-text">{t('ready')}</p>
                        <p className="description offer-text white-text">{t('consultation')}</p>
                    </div>
                    
                    <div className="schedule-banner-content schedule-button-container">
                        <NavLink to="/Contacts"><button className="schedule-button gradient-button">{t('schedule')}</button></NavLink>
                    </div>
                </div>
            </div>

        </div>
    );
}