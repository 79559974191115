import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import menuLogo from '../../Assets/images/menu-logo.svg';
import { ImQuotesLeft } from "react-icons/im";

export class Testimonials extends Component {
  render() {
    return (
      <Carousel autoPlay={true} showThumbs={false} showIndicators={false} showStatus={false} infiniteLoop={true}>
        <div>
          <p className="title">What Our People Say</p>
          <h3 className="description">Testimonials</h3>
          <p><ImQuotesLeft className="quote"/></p>
          <p className="paragraph testimonial-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. Ut eleifend pretium dui ut facilisis. </p>
          <h6>GEORGE GREEN</h6>
        </div>
        <div>
          <p className="title">What Our People Say</p>
          <h3 className="description">Testimonials</h3>
          <p><ImQuotesLeft className="quote"/></p>
          <p className="paragraph testimonial-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. Ut eleifend pretium dui ut facilisis. </p>
          <h6>RACHEL BROWN</h6>
        </div>
        <div>
          <p className="title">What Our People Say</p>
          <h3 className="description">Testimonials</h3>
          <p><ImQuotesLeft className="quote"/></p>
          <p className="paragraph testimonial-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. Ut eleifend pretium dui ut facilisis. </p>
          <h6>THOMAS HAN</h6>
        </div>
      </Carousel>
    );
  }
}
export default Testimonials 