import React, { Component } from 'react';
import './Footer.css';
import logo from '../../Assets/images/logo.jpg';

export class Footer extends Component {
    
    render() {
        return (
            <header className="App-footer">
                <div className="footer">
                    <div className="footer-grid-item footer-grid-container">
                        <div>
                            <img className="footer-logo" src={logo}/>
                        </div>
                        <div>
                            <p className="paragraph footer-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu.</p>
                        </div>
                    </div> 
                    <div className="footer-grid-item">
                        <p className="paragraph footer-paragraph">Legal</p>
                        <p className="paragraph footer-paragraph">Privacy Policy</p>
                        <p className="paragraph footer-paragraph">Terms and Conditions</p>
                    </div> 
                    <div className="footer-grid-item">
                        <p className="paragraph footer-paragraph">FAQ</p>
                        <p className="paragraph footer-paragraph">News and Updates</p>
                        <p className="paragraph footer-paragraph">Connect</p>
                    </div> 
                    <div className="footer-grid-item">
                        <p className="paragraph footer-paragraph">Facebook</p>
                        <p className="paragraph footer-paragraph">Twitter</p>
                        <p className="paragraph footer-paragraph">Linkedin</p>
                    </div> 
                </div>

                <div className="sub-footer">
                    <p className="copyright white-text">Copyright @2021 Netony. All Rights Reserved.</p>  
                </div>
            </header>
        );
    }
}
export default Footer