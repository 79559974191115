import React, { Component } from 'react';
import { IoCallOutline, IoArrowForwardSharp } from "react-icons/io5";
import Testimonials from '../Testimonials/testimonials';
import { NavLink } from "react-router-dom";


import './Services.css';

export class Services extends Component {
    
    render() {
        return (
            <div>
                <div className="services services-heading">
                    <p className="description">Services</p>
                    <p className="paragraph services-heading-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum. </p>
                </div>
                
                <div className="services">
                    <div className="services-container grid-container">
                        <div className="services-grid-item services-image"></div>
                        <div className="services-grid-item">
                            <div className="">
                                <p className="description">Lorem Ipsum </p>
                                <p className="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. Ut eleifend pretium dui ut facilisis. Morbi fermentum nisi lacus, id dolor porta non. Sed gravida egestas interdum. In hac habitasse platea dictumst. Phasellus tincidunt sollicitudin ex, a commodo lorem faucibus ac.</p>

                                <NavLink to="/Contacts"><button className="gradient-button talk-button">GET STARTED</button></NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="services milky">
                    <div className="services-container grid-container">
                        <div className="services-grid-item">
                            <div className="">
                                <p className="description">Lorem Ipsum </p>
                                <p className="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. Ut eleifend pretium dui ut facilisis. Morbi fermentum nisi lacus, id condimentum dolor porta non. Sed gravida egestas interdum. In hac habitasse platea dictumst. Phasellus tincidunt sollicitudin ex, a commodo lorem faucibus ac.</p>

                                <NavLink to="/Contacts"><button className="gradient-button talk-button">GET STARTED</button></NavLink>
                            </div>
                        </div>
                        <div className="services-grid-item services-image-2"></div>
                    </div>
                </div>

                <div className="testimonials testimonial-section">
                    <Testimonials/>
                </div>

                <div className="services solution-section">
                    <div className="solution-header">
                        <p className="description solution-text">Need a personalized solution?</p>
                        <p className="paragraph solution-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinar nunc eu, elementum diam. Maecenas vitae finibus arcu, vel bibendum risus. Mauris ac dictum mauris. </p>
                    </div>

                    <div className="grid-container">
                        <div className="solution-grid-item right">
                            <NavLink to="/Contacts"><button className="gradient-button solution-button"><IoCallOutline className="call"/>  1-001-234-5678</button></NavLink>
                        </div>
                        <div className="solution-grid-item left">
                            <NavLink to="/Contacts"><p className="solution-contact"> CONTACT US ONLINE <IoArrowForwardSharp className="arrow-right"/> </p> </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Services