import React, { Component } from 'react';
import FaqDropdown from '../FaqDropdown/FaqDropdown';

import './Faqs.css';

export class Faqs extends Component {
    
    render() {
        return (
            <div>
                <div className="faqs-heading">
                    <p className="description">Frequently Asked Questions</p>
                </div>

                <FaqDropdown/>

                <div className="faqs faqs-container">
                    <p className="description">Didn't find the answer?</p>
                    <div className="faqs-form">
                        
                        <p className="paragraph faqs-heading-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue mollis, pulvinarnunceufra, elementum diam. </p>
                        <input className="form-input" type="text" placeholder="Name*"></input>
                        <input className="form-input" type="email" placeholder="Email*"></input>
                        <textarea className="form-input" type="text" placeholder="Message*" rows="7"></textarea>

                        <button className="gradient-button faq-send-button">SEND QUESTION</button>
                    </div>
                    
                </div>
            </div>
        );
    }
}
export default Faqs