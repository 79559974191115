import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Faqs from './Components/Faqs/Faqs';
import Contacts from './Components/Contacts/Contacts';
import Footer from './Components/Footer/Footer';

import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>} /> 
          <Route path='/About' element={<About/>} />
          <Route path='/Services' element={<Services/>} />
          <Route path='/Faqs' element={<Faqs/>} />
          <Route path='/Contacts' element={<Contacts/>} />
        </Routes>
      </BrowserRouter>
      <Footer/>
    </div>
  );
}

export default App;